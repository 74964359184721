<script setup lang="ts">
import { ListItemProps } from "./ListItem.props"
import { iconsSrcMap } from "./ListItem.iconMapping"
import { sm } from "~/utils/breakpoints"
import { customPaths } from "~/utils/constants"

const props = defineProps<ListItemProps>()

defineEmits<{ (e: "onSelected", value: string): void }>()

const isMounted = useMounted()

const hideOnMobile = computed(
  () => isMounted.value && sm.value && props.hiddenOnMobile
)
</script>

<template>
  <AppLink
    v-if="!hideOnMobile"
    :to="
      slug
        ? `${customPaths.profile.root}/${slug}`
        : `${customPaths.profile.root}/`
    "
    class="
      wrapper-list_item
      md:left-padded
      flex
      items-center
      gap-2
      px-4
      py-2
      md:gap-3
      md:pt-3
    "
    :class="[isSelected ? 'md:bg-tiffany-10' : 'md:bg-white']"
    @click="$emit('onSelected')"
  >
    <div
      class="inline-block rounded-full bg-tiffany-10 p-1 text-green-main md:p-2"
      :class="{ 'md:bg-white': isSelected }"
    >
      <UtilsIcon v-bind="iconsSrcMap[iconType]" class="h-5 w-5 md:h-4 md:w-4" />
    </div>
    <span
      v-if="label"
      class="beaver"
      :class="{ 'md:beaver-bold md:text-green-main': isSelected }"
    >
      {{ label }}
    </span>
  </AppLink>
</template>
