export type IconType = keyof typeof iconsSrcMap

export const iconsSrcMap = {
  Board: { name: "Board.svg", color: "green-main" },
  User: { name: "User.svg", color: "green-main" },
  Heart: { name: "HeartOutline.svg", color: "green-main" },
  ShoppingBag: { name: "ShoppingBag.svg", color: "green-main" },
  Pin: { name: "Pin.svg", color: "green-main" },
  CreditCard: { name: "CreditCard.svg", color: "green-main" },
  Book: { name: "Book.svg", color: "green-main" },
  PriceTag: { name: "Pricetag.svg", color: "green-main" },
  Box: { name: "Box.svg", color: "green-main" },
  Award: { name: "Award.svg", color: "green-main" }
}
