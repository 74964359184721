<script setup lang="ts">
import type { DisplayedUsernameProps } from "./DisplayedUsername.props"
import { sm } from "~/utils/breakpoints"

const props = withDefaults(defineProps<DisplayedUsernameProps>(), {
  handleClick: () => null,
  showPlaceholder: false,
  iconColor: undefined,
  iconClass: "",
  textClass: "",
  textBoldMobile: false
})

const { headerInfo } = await useHeaderConnect()
const isLogged = useIsLogged()
const { t } = useI18n()

const welcomedUsername = computed(() =>
  props.showPlaceholder && isLogged && !headerInfo.value.username
    ? t("dialogsAndModals.welcome.myProfile")
    : t("dialogsAndModals.standard.hello")
)
</script>

<template>
  <div
    @click="handleClick"
    class="
      wrapper-user_banner
      md:left-padded
      flex
      items-center
      gap-2
      bg-grey-main
      px-4
      py-6
      md:mb-0
      md:bg-white
    "
  >
    <UtilsIcon
      fetch-priority="high"
      :preload="true"
      name="UserLogged.svg"
      :color="iconColor"
      :class="`h-6 w-6 flex-shrink-0 text-green-500 md:h-10 md:w-10 ${iconClass}`"
    />
    <span
      v-if="welcomedUsername"
      :class="`pig md:hyppo-bold line-clamp-1 break-all ${textClass}`"
    >
      {{ welcomedUsername }}
      <span
        v-if="headerInfo.username"
        :class="{ 'font-bold': textBoldMobile && sm }"
      >
        {{ headerInfo.username }}
      </span>
    </span>
  </div>
</template>
